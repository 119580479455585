import { createHandler } from '@/util/events.js'

createHandler('reviewTypeSelect', function (ev) {
  const targetSource = this.dataset.appSource
  const allReviewTypeSelect = document.querySelectorAll('.reviews-select')
  const reviews = document.querySelectorAll('.review-container')

  allReviewTypeSelect.forEach((review) => {
    review.classList.remove('border-white')
  })

  this.classList.add('border-b-2', 'border-white')

  reviews.forEach((review) => {
    if (targetSource === 'all') {
      return review.classList.remove('hidden')
    }

    if (review.dataset.reviewSource !== targetSource) {
      review.classList.add('hidden')
    } else {
      review.classList.remove('hidden')
    }
  })
})
