import { createHandler } from '@/util/events.js'

createHandler('closeModalFromBackground', function (ev) {
  if (ev.target instanceof HTMLDialogElement) {
    this.close()
  }
})

createHandler('openVideoReview', function (ev) {
  const videoId = this.dataset.youtubeId

  const videoModal = document.querySelector('[data-modal-id="youtubeVideo"]')
  const videoIframe = videoModal.querySelector('iframe')

  videoIframe.src = `https://www.youtube.com/embed/${videoId}`

  videoModal.showModal()
})

createHandler('closeModalClicked', function () {
  this.closest('dialog')?.close()
})
