document.addEventListener('paypalApprove', onExpressPaymentMethod)
document.addEventListener('googlePayDataLoaded', onExpressPaymentMethod)
document.addEventListener('applePayPaymentAuthorized', onExpressPaymentMethod)

async function onExpressPaymentMethod(ev) {
  const { paymentData } = ev.detail
  const form = document.querySelector('#expressCheckout')
  form.querySelector("[name='express_data']").value =
    JSON.stringify(paymentData)

  form.submit()
}
