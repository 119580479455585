import { createHandler } from '@/util/events.js'

const isCompatible = (year, make, fuelType) => {
  if (year === '' || make === '' || fuelType === '') return null
  if (year < 1996) return false
  if (fuelType === 'Diesel' && year < 2006) return false
  if (fuelType === 'Electric') return false
  return true
}

createHandler('vehicleCompatibility', function () {
  const resultElement = document.querySelector('.compatibility-results')
  const compatibleText = resultElement.querySelector('.compatible')
  const nonCompatibleText = resultElement.querySelector('.not-compatible')
  const ctaContainer = document.querySelector('.cta-compatibility-container')
  let compatible = null
  const year = this.querySelector('.year-select').value
  const make = this.querySelector('.make-select').value
  const fuelType = this.querySelector('.fuel-type-select').value

  compatible = isCompatible(year, make, fuelType)

  if (compatible === true) {
    resultElement.classList.add('text-green')
    compatibleText.classList.remove('hidden')
    nonCompatibleText.classList.add('hidden')
    ctaContainer.classList.remove('hidden')
  } else if (compatible === false) {
    resultElement.classList.remove('text-green')
    nonCompatibleText.classList.remove('hidden')
    compatibleText.classList.add('hidden')
    ctaContainer.classList.add('hidden')
  }
})
