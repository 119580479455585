import { createHandler } from '@/util/events.js'

let warrantySelected = false
let checkoutURL = ''
createHandler('initialFunnelLoad', function () {
  updateCheckoutInfo()
  uncheckWarrantyOnStart()
})

createHandler('redirectToCheckout', function () {
  window.location.href = checkoutURL
})

createHandler('changePackQuantity', function () {
  if (this.classList.contains('selected')) {
    return
  }

  const warrantyInput = document.querySelector('#warrantyInput')
  const newPrice = warrantyInput.checked
    ? (
        parseFloat(this.dataset.subtotalPrice) +
        parseFloat(warrantyInput.dataset.warrantyPrice)
      ).toFixed(2)
    : this.dataset.subtotalPrice

  document.querySelector('.subtotal-price').innerHTML = newPrice

  document.querySelector('.strikethrough-price').innerHTML =
    this.dataset.strikethroughPrice

  document
    .querySelectorAll('button.pack-button.selected')
    .forEach((element) => {
      element.classList.remove('selected')
    })
  this.classList.add('selected')
  updateGooglePayPriceAmount(newPrice)
  updateCheckoutInfo()
  updateBuyBoxSensorImage(this.dataset.quantity)
})

createHandler('changeWarranty', function () {
  const warrantyInput = document.querySelector('#warrantyInput')
  const subtotalPrice = document.querySelector('.subtotal-price')
  const warrantyPrice = this.dataset.warrantyPrice

  warrantySelected = warrantyInput.checked
  const newSubtotalPrice = warrantySelected
    ? Number(
        parseFloat(subtotalPrice.innerHTML) + parseFloat(warrantyPrice),
      ).toFixed(2)
    : Number(
        parseFloat(subtotalPrice.innerHTML) - parseFloat(warrantyPrice),
      ).toFixed(2)
  subtotalPrice.innerHTML = newSubtotalPrice
  updateGooglePayPriceAmount(newSubtotalPrice)
  updateCheckoutInfo()
})

createHandler('switchThumbnail', function (ev) {
  const { index } = ev.detail
  highlightThumbnail(document.querySelector('.thumbnail-container'), index - 1)
})

createHandler('thumbnailClicked', function () {
  const carousel = document.querySelector('#main-carousel')?.splide
  if (carousel == null) {
    return
  }

  const index = parseInt(this.dataset.index, 10)
  highlightThumbnail(this.closest('.thumbnail-container'), index)
  carousel.go(index + 1)
})

function highlightThumbnail(container, index) {
  const strindex = index.toString()
  container.querySelectorAll('[data-index]').forEach((element) => {
    element.classList.toggle('border-green', element.dataset.index === strindex)
  })
}

const updateBuyBoxSensorImage = (quantity) => {
  document.querySelectorAll('.buybox-sensor-image').forEach((image) => {
    if (image.classList.contains(quantity)) {
      image.classList.remove('hidden')
    } else {
      image.classList.add('hidden')
    }
  })
}

const updateGooglePayPriceAmount = (amount) => {
  document.querySelector('googlepay-button').setAttribute('totalprice', amount)
}

const updateCheckoutInfo = () => {
  const selectedOffer = document.querySelector('.pack-button.selected')?.dataset
    ?.offerSlug
  if (selectedOffer == null) {
    return
  }

  const funnelSlug = document.querySelector('.buy-box')?.dataset?.funnel
  if (funnelSlug == null) {
    return
  }

  const warrantyInput = document.querySelector('#warrantyInput')
  const formActionUrl = document.querySelector('#expressCheckout')

  const newOfferSlug = `${selectedOffer}${warrantyInput.checked ? 'w' : ''}`
  const newCheckoutURL = `/buy/${funnelSlug}/${newOfferSlug}`

  formActionUrl.action = newCheckoutURL
  checkoutURL = newCheckoutURL
}

const uncheckWarrantyOnStart = () => {
  // FIXME: This needs to be fixed
  // When doing back from the checkout, if the user purchased it with warranty input
  // the page is loaded with this as checked
  document.querySelector('#warrantyInput').checked = false
  document.querySelector('#warrantyInput').defaultChecked = false
}
